import React from "react";
import { useState } from "react";
import "./App.css"



const MODE = "NOTDev"

let example = [{user: "Pap", message:"hello", date: "14 March 2022 14:00"},
                 {user: "Mam", message:"hello Tan, how are you?", date:"15 March 2022 15:00"},
                 {user: "Pap", message:"I am fine", date:"15 March 2022 16:00"}
]



let showinputmessage = false

let user = ""

function App() {

const [todolist, setTodoList] = useState([])
const [shownew, setShowNew] = useState(false)
const [allowedit, setAllowEdit] = useState(false)
const [hidelogin, setHideLogin] = useState(false)

const contactlist = ["Jay", "Jas", "Mam", "Pap"]

let timer

function submitForm(e){
  e.preventDefault();

  let username = e.target.loginname.value
  user = username
  let pass = e.target.loginpass.value

  if(MODE === "Dev"){
    console.log("development mode")
    setTodoList(example)
  }
  else
  {
    fetch("https://serveer.oiltek.nl/authentication",{
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify({username:username, pass:pass})
    }).
    then(resp=>resp.json()).
    then(reply=>{console.log(reply);
                  setTodoList(reply.todos);
                  if(reply.todos !==""){setHideLogin(true)}
                })
  }


}

// function submitForm(e){
//   e.preventDefault();
//   let username = e.target.loginname.value
//   user = username
//   let pass = e.target.loginpass.value
//   fetch("http://localhost:3001/authentication",{
//     method:"POST",
//     headers:{"Content-Type":"application/json"},
//     body:JSON.stringify({username:username, pass:pass})
//   }).then(resp=>resp.json()).then(reply=>{console.log(reply); 
//     setTodoList(reply.todos)

//     if(reply.todos !==""){setHideLogin(true)}
//   })
// }

function Loginprocess(){
  return(
    <div>
      <form onSubmit={submitForm}>
        <table>
          <tbody>
                <tr>
                  <td> <label htmlFor="loginname">Enter Login name : </label></td>   <td> <input type="text" id="loginname" name="loginname"/></td>
                </tr>
                <tr>
                  <td>   <label htmlFor="loginpass">Enter Login password : </label> </td>   <td> <input type="text" id="loginpass" name="loginpass"/></td>
                </tr>
                <tr>
                  <td></td>
                  <td><input type="submit" /></td>
                </tr>
            </tbody>
        </table>
       
    
      </form>
    </div>
  )
}

function submitNewMessage(e){
  e.preventDefault()
  
  setAllowEdit(true)
  let newitem =  {user: user, message: e.target.newmess.value, date: Date().slice(0,25)}  //Date().slice(0,25)
  let temparray = [...todolist]
  setTodoList([newitem, ...temparray])

let newarray = [newitem, ...temparray]

  timer = setTimeout(()=>{
    console.log("timer fired at submit new message")
    setAllowEdit(false)
    showinputmessage = false
  }, 5000)

  console.log("fetching 1")
  fetch("https://serveer.oiltek.nl/savetoserver",{
    method:"POST",
    headers:{"Content-Type":"application/json"},
    body: JSON.stringify({arrays: newitem})
  }).then(resp=>resp.json()).then(result=>console.log(result))
}


function submitNewEditMessage(e){
  e.preventDefault()
  setAllowEdit(true)
  let newitem =  {user: user, message: e.target.newmess.value, date: Date().slice(0,25)}
  let temparray = [...todolist]
  
  temparray.shift()
  setTodoList([newitem, ...temparray])
  let newarray = [newitem, ...temparray]
  timer = setTimeout(()=>{
    setAllowEdit(false)
    showinputmessage = false
  }, 5000)

  fetch("https://serveer.oiltek.nl/saveedittoserver",{
    method:"POST",
    headers:{"Content-Type":"application/json"},
    body: JSON.stringify({arrays: newitem})
  }).then(resp=>resp.json()).then(result=>console.log(result))
}

// function submitNewMessage(e){
//   e.preventDefault()
//   console.log("submit message")
//   console.log("todolist")
//   console.log(todolist)
//   setAllowEdit(true)
//   let newitem =  {user: user, message: e.target.newmess.value, date: Date().slice(0,25)}
//   let temparray = [...todolist]

// temparray.shift()

//   setTodoList([newitem,...temparray])
//   // temparray.unshift(newitem)

//   timer = setTimeout(()=>{
//     setAllowEdit(false)
//   }, 5000)

//   fetch("http://localhost:3001/savetoserver",{
//     method:"POST",
//     headers:{"Content-Type":"application/json"},
//     body: JSON.stringify({arrays: [newitem, ...temparray]})
//   })
// }


function clickCancel(){
  let temp = [...todolist]
  // temp.shift()
  setTodoList(temp)
  showinputmessage = false
}


function shownewmessage(){
  

  console.log("new message")
  let item =  {user: user, message: 
                <form onSubmit={submitNewMessage}>
                    <input type="text" name="newmess" className="inputnew"/> 
                    <input type="submit" /> 
                    <button onClick={clickCancel}>cancel</button>
                  </form>,
                date: ""
              }
  
  if(!showinputmessage){
    setTodoList(prev=>[item,...prev])
  }
 
  showinputmessage = true;

  setTimeout(() => {
    const ele = document.querySelector(".inputnew")
    ele.focus()
  }, 1000);
}

function clickdelete(){
  clearTimeout(timer)
  let temp = [...todolist];
  console.log(temp)
  temp.shift(temp)
  console.log(temp)
  setTodoList(temp)
  fetch("https://serveer.oiltek.nl/deletelast",{
    method:"GET",
    // headers:{"Content-Type":"application/json"},
    // body: JSON.stringify({arrays: temp})
  }).then(resp=>resp.json()).then(result=>console.log(result))

  setAllowEdit(false)
}

// function clickdelete(){
//   clearTimeout(timer)
//   let temp = [...todolist];
//   console.log(temp)
//   temp.shift(temp)
//   console.log(temp)
//   setTodoList(temp)

//   fetch("http://localhost:3001/savetoserver",{
//     method:"POST",
//     headers:{"Content-Type":"application/json"},
//     body: JSON.stringify({arrays: temp})
//   })

//   setAllowEdit(false)
// }




function clickedit(e){
    e.preventDefault();
    setAllowEdit(false)
    let temp = [...todolist];
    temp.shift()
    let item =  {user: user, message: 
        <form onSubmit={submitNewEditMessage}>
            <input type="text" name="newmess" defaultValue={todolist[0].message}/> <input type="submit" id="inputbutton" /> 
        </form>,
       date: ""}
    setTodoList([item, ...temp])   
}

// function clickedit(e){
//   e.preventDefault();
//   setAllowEdit(false)
//   let temp = [...todolist];
//   temp.shift()
//   let item =  {user: user, message: 
//       <form onSubmit={submitNewMessage}>
//           <input type="text" name="newmess" defaultValue={todolist[0].message}/> <input type="submit" /> 
//       </form>,
//      date: ""}

//  setTodoList([item, ...temp])    

// }




  return (
<div>
 <h1>Personal Messaging</h1> 
 <div style={{display: hidelogin? "Block": "Block"}}>
   <button onClick={()=>{setHideLogin(!hidelogin); console.log("clicked show login")}}>{hidelogin? "show login":"hide login"}</button>
</div>
<div style={{display: hidelogin? "none": "inline-Block"}}>
    <Loginprocess />
</div>
  <br/>
{/* <button onClick={tester}>TEST button</button> */}
<h2>
{user !== ""? "Welcome " + user : ""}
</h2>

<br />
<button id="createmessage" onClick={shownewmessage}>Create New Message</button>
<br/>
<br/>
  {todolist?.map((todo, index)=>{return(
      <div key={crypto.randomUUID()} style={{marginLeft: todo.user.toLowerCase() === user.toLowerCase()? "5px": "50px", backgroundColor:'offwhite', border:"black 2px solid"}} className="messagebox">
         <div><b>{todo.user}</b> <div style={{display:"inline-block", fontSize:"20px"}}>{todo.date}</div> </div>
         <div className="messagesquare" style={{minHeight: "50px", padding: "10px", backgroundColor:"beige"}}>{todo.message} </div>

         <div style={{display: index === 0 && allowedit? "inlineBlock":"none"}} id={index === 0? "first":"normal"}><button onClick={clickedit}>edit</button><button onClick={clickdelete}>delete</button></div>

      </div>
  )})}

<br/>
<br/>
<div>


</div>


</div>
  );
}

export default App;
